import {inject, Injectable} from '@angular/core';
import {ClientService} from "../client/client.service";
import {Observable, of, switchMap} from "rxjs";
import {scexternal, scinternal} from "../client/client";

@Injectable({
  providedIn: 'root'
})
export class AlgorithmsDataService {
  clientService = inject(ClientService);

  constructor() { }

  getAlgorithmByDocID(docID: string) {
    return this.clientService.client.pipe(
      switchMap(client => client.scinternal.GetAlgorithmByDocID(docID))
    );
  }

  getAlgorithmByID(id: number) {
    return this.clientService.client.pipe(
      switchMap(client => client.scinternal.GetAlgorithmByID(id))
    );
  }

  getFeaturedAlgorithm() {
    return this.clientService.client.pipe(
      switchMap(client => client.scinternal.GetFeaturedAlgorithm())
    );
  }

  listAlgorithmPreviews(listAlgorithmRequest: scexternal.ListAlgorithmsRequest) {
    return this.clientService.client.pipe(
      switchMap(client => client.scexternal.ListAlgorithms(listAlgorithmRequest))
    );
  }

  listAlgorithms(listAlgorithmsRequest: scinternal.ListAlgorithmsRequest) {
    return this.clientService.client.pipe(
      switchMap(client => client.scinternal.ListAlgorithms(listAlgorithmsRequest))
    );
  }


  createAlgorithm(algorithmDetails: scinternal.CreateAlgorithmRequest) {
    return this.clientService.client.pipe(
      switchMap(client => client.scinternal.CreateAlgorithm(algorithmDetails))
    );
  }

  updateAlgorithm(id: number, params: scinternal.UpdateAlgorithmRequest) {
    return this.clientService.client.pipe(
      switchMap(client => client.scinternal.UpdateAlgorithm(id, params))
    )
  }

  getRuleProperties() {
    return this.clientService.client.pipe(
      switchMap(client => client.scinternal.ListRuleProperties())
    );
  }

  getRuleOperations(params: scinternal.ListRuleOperationsRequest) {
    return this.clientService.client.pipe(
      switchMap(client => client.scinternal.ListRuleOperations(params))
    );
  }
  getRuleValues(params: scinternal.ListRuleValuesRequest) {
    return this.clientService.client.pipe(
      switchMap(client => client.scinternal.ListRuleValues(params))
    );
  }

  cloneAlgorithm(id: number, params: scinternal.AlgorithmCloneRequest) {
    return this.clientService.client.pipe(
      switchMap(client => client.scinternal.CloneAlgorithm(id, params))
    );
  }

  ListAlgorithmRevisions(docID: string) {
    return this.clientService.client.pipe(
      switchMap(client => client.scinternal.ListAlgoritmRevisions(docID))
    );
  }

  archiveAlgorithm(docID: string) {
    return this.clientService.client.pipe(
      switchMap(client => client.scinternal.ArchiveAlgorithm(docID))
    );
  }

  updateAlgorithmMetadata(docID : string, params: scinternal.UpdateAlgorithmMetaDataRequest) {
    return this.clientService.client.pipe(
      switchMap(client => client.scinternal.UpdateAlgorithmMetadata(docID, params))
    );
  }

  checkFormula(template: string, variables: {[key: string]: any}, algorithmId: number) {
    return this.clientService.client.pipe(
      switchMap(client => client.scinternal.CheckFormula({
        formula_template: template,
        templated_variables: variables,
        algorithm_id: algorithmId
      }))
    );
  }

  toggleAlgorithmAsFeatured(docID: string) {
    return this.clientService.client.pipe(
      switchMap(client => client.scinternal.ToggleFeatureAlgorithm(docID))
    );
  }
}
