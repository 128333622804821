import { Routes } from "@angular/router";
import { LoginGuard } from "src/shared/guards/login.guard";

export const TrialsRoutes: Routes = [
    {
      path: "trials",
      canActivate: [LoginGuard],
      loadComponent: () => import('./pages/trials/trials.component').then(m => m.TrialsComponent),
      children: [
        {
        path:'',
        redirectTo: 'trials',
        pathMatch: 'full'
        },
        {
          path: "trials",
          loadComponent: () => import('./pages/listtrials/listtrials.component').then(m => m.ListTrialsComponent)
        },
        {
          path: "add",
          loadComponent: () => import('./pages/createtrial/createtrial.component').then(m => m.CreateTrialComponent)
        },
        {
          path: ':id',
          loadComponent: () => import('./pages/gettrial/gettrial.component').then(m => m.GetTrialComponent),
        },
        {
          path: ':id/update',
          loadComponent: () => import('./pages/updatetrial/updatetrial.component').then(m => m.UpdateTrialComponent)
        },
        {
          path: ':id/update-status',
          loadComponent: () => import('./pages/updatetrialstatus/updatetrialstatus.component').then(m => m.UpdateTrialStatusComponent)
        },
        {
          path: ':id/add-participant',
          loadComponent: () => import('./pages/add-participant/add-participant.component').then(m => m.AddParticipantComponent)
        },
        {
          path: ':id/participant-steps/:participantId',
          loadComponent: () => import('./pages/participants/participants.component').then(m => m.ParticipantComponent)
        },
        {
          path: ':id/participant/:participantId',
          loadComponent: () => import('./pages/participant-info/participant-info.component').then(m => m.ParticipantInfoComponent)
        },
        {
          path: ':id/create-step',
          loadComponent: () => import('./pages/createtrialstep/createtrialstep.component').then(m => m.CreateTrialStepComponent)
        },
        {
          path: ':id/steps',
          loadComponent: () => import('./pages/listtrialsteps/listtrialsteps.component').then(m => m.ListTrialStepsComponent)
        },
        {
          path: ':id/steps/:stepId/update',
          loadComponent: () => import('./pages/updatetrialstep/updatetrialstep.component').then(m => m.UpdateTrialStepComponent)
        }
      ]
    }
  ];
  